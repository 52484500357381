import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import io from 'socket.io-client';
import "./App.css";
import globeImage from '../assets/hala_new.png';
import '@fortawesome/fontawesome-free/css/all.min.css';


const socket = io(process.env.REACT_APP_API_URL || 'http://127.0.0.1:8060'); // Replace with your server URL

const ClientPage = () => {
    const [youtubeLink, setYoutubeLink] = useState('');
    const [transcription, setTranscription] = useState('');
    const [hwScore, setHwScore] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8060';
    const [sidebarOpen, setSidebarOpen] = useState(false);
    console.log('API URL:', apiUrl);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        axios.get(`${apiUrl}/api/get_link`)
            .then(response => {
                setYoutubeLink(response.data.link);
            })
            .catch(error => {
                console.error('Error fetching link:', error);
            });

        socket.on('linkUpdated', (newLink) => {
            setYoutubeLink(newLink);
        });

        return () => {
            socket.off('linkUpdated');
        };
    }, [apiUrl]);

    useEffect(() => {
        let transcriptionInterval;
        if (youtubeLink) {
            const fetchTranscript = () => {
                console.log('Fetching transcription...');
                axios.get(`${apiUrl}/api/get_transcription`)
                    .then(response => {
                        console.log('Transcription response:', response.data);
                        setTranscription(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching transcription:', error);
                    });
            };

            fetchTranscript();
            transcriptionInterval = setInterval(fetchTranscript, 500);
        }

        return () => {
            clearInterval(transcriptionInterval);
        };
    }, [youtubeLink, apiUrl]);

    useEffect(() => {
        let hwScoreInterval;
        if (youtubeLink) {
            const fetchHwScore = () => {
                console.log('Fetching HW score...');
                axios.get(`${apiUrl}/api/get_hw_score`)
                    .then(response => {
                        console.log('HW score response:', response.data);
                        setHwScore(Array.isArray(response.data) ? response.data : []);
                    })
                    .catch(error => {
                        console.error('Error fetching HW score:', error);
                    });
            };

            fetchHwScore();
            hwScoreInterval = setInterval(fetchHwScore, 1000);
        }

        return () => {
            clearInterval(hwScoreInterval);
        };
    }, [youtubeLink, apiUrl]);

    const videoId = youtubeLink ? youtubeLink.split('v=')[1] : '';

    // const handleQuestionSubmit = (e) => {
    //     e.preventDefault();
    //     axios.post(`${apiUrl}/api/submit_question`, { question: newQuestion })
    //         .then(response => {
    //             setAnswer(response.data.answer);
    //             setNewQuestion('');
    //         })
    //         .catch(error => {
    //             console.error('Error submitting question:', error);
    //         });
    // };

    // const renderAnswer = (answer) => {
    //     if (!answer) return null;
    //     const sentences = answer.split('\n').map(sentence => sentence.trim());
    //     return (
    //         <ul>
    //             {sentences.map((sentence, index) => (
    //                 <li key={index}>{sentence.replace(/^-\s*/, '')}</li>
    //             ))}
    //         </ul>
    //     );
    // };

    return (
        <div className="container">
            <header className="header">
                <div className="company_header">
                    <a href="https://alphanew.macroxstudio.com" className="nav-link active" id="company_header">
                        <h4>MacroX: Know Faster Invest Better</h4>
                    </a>
                </div>
                <div className="page-header">
                    <h4>Live Video Stance</h4>
                </div>
            </header>
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <a href="#">Alpha Page</a>
                <a href="#">US Real Estate</a>
                <a href="#">US Labor Market</a>
            </div>
            <main className="main-content">
                <div className="image-container">
                    <img src={globeImage} alt="Globe" className="globe-image" />
                </div>
                {youtubeLink ? (
                    <>
                        <div id="live-video-wrapper">
                            <div id="live-video-container">
                                <iframe
                                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&controls=0&loop=1&playlist=${videoId}`}
                                    title="Live Video"
                                ></iframe>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div id="content-container">
                            <div id="transcription-container">{transcription}</div>
                            <div id="hw-score-plot">
                                <Plot
                                    data={[
                                        {
                                            x: Array.from({ length: hwScore.length }, (_, i) => i + 1),
                                            y: hwScore,
                                            type: 'scatter',
                                            mode: 'lines+markers',
                                            line: { shape: 'spline', color: 'orange' },
                                        },
                                    ]}
                                    layout={{
                                        title: {
                                            text: 'Hawkishness Score Over Time',
                                            font: { size: 20 }, // Adjust the font size as needed
                                            x: 0.5, // Center horizontally
                                            y: 1, // Shift towards the top (1 is the top)
                                            xanchor: 'center',
                                            yanchor: 'top',
                                        },
                                        paper_bgcolor: '#272c30',
                                        plot_bgcolor: '#272c30',
                                        font: { color: '#fff', family: 'Roboto' },
                                        margin: { t: 50 }, // Adjust the top margin to give space for the title
                                    }}
                                    useResizeHandler={true}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </div>
                        </div>
                        {/* <div id="qa-section">
                            <h3>Chatbot</h3>
                            <form onSubmit={handleQuestionSubmit}>
                                <input
                                    type="text"
                                    placeholder="Ask a question ..."
                                    value={newQuestion}
                                    onChange={(e) => setNewQuestion(e.target.value)}
                                    required
                                />
                                <button type="submit">Submit</button>
                            </form>
                            {answer && (
                                <div style={{ marginTop: '20px' }}>
                                    <div id="answer-container">{renderAnswer(answer)}</div>
                                </div>
                            )}
                        </div> */}
                    </>
                ) : (
                    <div style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                        Live Stream Not Started Yet.
                    </div>
                )}
            </main>
        </div>
    );
};
    
export default ClientPage;
