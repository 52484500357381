import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPanel = () => {
    const [youtubeLink, setYoutubeLink] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL || 'http://34.136.28.213:8060/';
    console.log('API URL:', apiUrl);

    useEffect(() => {
        axios.get(`${apiUrl}/api/get_link`)
            .then(response => {
                setYoutubeLink(response.data.link);
            })
            .catch(error => {
                console.error('Error fetching link:', error);
            });
    }, [apiUrl]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${apiUrl}/api/set_link`, { link: youtubeLink })
            .then(response => {
                setYoutubeLink(response.data.link);
            })
            .catch(error => {
                console.error('Error setting link:', error);
            });
    };

    const handleStop = () => {
        axios.post(`${apiUrl}/api/stop_scripts`)
            .then(response => {
                setYoutubeLink(''); // Reset the input field
            })
            .catch(error => {
                console.error('Error stopping link:', error);
            });
    };

    return (
        <div>
            <h3>Admin Panel: Update YouTube Link</h3>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="youtube-url"
                    placeholder="Insert video link"
                    value={youtubeLink}
                    onChange={(e) => setYoutubeLink(e.target.value)}
                    required
                />
                <button type="submit">Submit</button>
            </form>
            <button onClick={handleStop}>Stop</button>
            <div style={{ color: 'blue' }}>Stored data: {youtubeLink}</div>
        </div>
    );
};

export default AdminPanel;
