import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ClientPage from './components/ClientPage';
import AdminPanel from './components/AdminPanel';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="/" element={<ClientPage />} />
            </Routes>
        </Router>
    );
};

export default App;